
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { AreaObject } from '@/store/modules/Assets/AreasModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'gov-editing',
    components: {
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const selectedItems = ref<any>({
        areaIds: [],
        govId: 0,
      });

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasUpdating'), [
          translate('assets'),
        ]);
      });
      // const { data } = await store.dispatch(Actions.GET_AREA, route.params.id);

      // const area = ref<AreaObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const onSubmitUpdate = async (values) => {
        debugger;
        console.log(values);
        console.log(selectedItems.value);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        // values.isRegisteredTrades =
        //   values.isRegisteredTrades == true ? true : false;
        // values.isAd = values.isAd == true ? true : false;
        // values.isAuction = values.isAuction == true ? true : false;
        const dataToUpdate = {
          areas: selectedItems.value.areaIds,
          govId: selectedItems.value.govId,
        };

        await store.dispatch(Actions.UPDATE_AREAS_GOV, {
          id: 1,
          data: dataToUpdate,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'areas-listing' });
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      await store.dispatch(Actions.GET_ALL_GOV_LIST);
      await store.dispatch(Actions.GET_AREAS_GOV_LIST);

      return {
        govs: computed(() => store.getters.allListGov),
        areas: computed(() => store.getters.allListAreasGov),
        onSubmitUpdate,
        submitButton,
        translate,
        goBack,
        can,
        selectedItems,
      };
    },
  });
